<template>
  <b-container fluid class="pb-4 px-lg-4 pt-4" v-if="payment">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12">
        <LogoCard :img="payment.business_logo" :alt="payment.business_name" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="8">
        <div
          class="wrapper-sales-invoices-pay md__border border-radius bg-color-write m-4 box-shadow"
        >
          <h6
            class="h6 text-color-primary text-center my-4 font-bold letter-spacing-4"
          >
            DETALLES DEL COBRO
          </h6>
          <div class="sales-invoices-pay-info mt-4 p-5">
            <b-row>
              <b-col xs="12" sm="12" md="8" lg="8">
                <small class="text-color-black mb-0">Concepto</small>
                <h4 class="h4 text-color-primary font-bold mb-4">
                  {{ getPaymentDescription }}
                </h4>

                <small class="text-color-black mb-0">Emitido por</small>
                <h4 class="h4 text-color-primary font-bold mb-4">
                  {{ payment.business_name }}
                </h4>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4">
                <div class="text-right">
                  <h4 class="h4 font-bold text-color-black">Valor</h4>
                  <h3 class="h3 font-bold">
                    <FormatAmount :amount="payment.total" />
                  </h3>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="sales-invoices-pay-details mt-4"></div>
          <div
            class="sales-invoices-pay-actions bg-color-primary p-4 mt-5 border-radius d-flex justify-content-center flex-element-columns"
          >
            <button
              class="btn md__btn-primary-outline btn-trans m-2"
              @click="invoiceDownload"
            >
              Descargar PDF
            </button>
            <ShareLinkWhatsapp :infoPaymentLink="payment">
              <button class="btn md__btn-primary-outline btn-trans m-2 w-100">
                Compartir por Whatsapp
              </button>
            </ShareLinkWhatsapp>
          </div>
        </div>
      </b-col>

      <b-col xs="12" sm="12" md="6" lg="4">
        <div
          class="wrapper-sales-invoices-pay md__border border-radius bg-color-write m-4 px-4 box-shadow"
        >
          <h6
            class="h6 text-color-primary text-center my-4 font-bold letter-spacing-4"
          >
            COMPLETAR PAGO
          </h6>
          <div id="pp-button" style="display:none"></div>

          <div class="d-flex flex-column mt-4">
            <label class="font-semi-bold mb-4 d-block">Valor a pagar</label>
            <el-radio-group v-model="paymentAmount">
              <el-radio label="total"
                ><FormatAmount :amount="payment.total"
              /></el-radio>
              <!-- <el-radio label="partial">Otro valor</el-radio> -->
            </el-radio-group>

            <template v-if="paymentAmount === 'partial'">
              <label class="font-semi-bold mt-4 d-block">Monto</label>
              <InputAmount :amount="form.total" @onChange="onInputAmount" />
            </template>

            <label class="font-semi-bold mt-4 d-block">Descripción</label>
            <el-input
              v-model="form.description"
              :placeholder="getPaymentDescription"
            ></el-input>
          </div>

          <h5 class="h5 text-color-primary font-bold my-4">Formas de pago</h5>

          <div class="mb-4">
            <el-radio-group v-model="paymentType" class="">
              <el-radio label="card" v-if="isEnablePayCard" class="mb-3"
                >Pago con Tarjeta</el-radio
              >
              <el-radio label="transf" v-if="isEnablePayTransf" class="mb-3"
                >Transferencia / Deposito</el-radio
              >
            </el-radio-group>

            <div class="pay-card" v-if="paymentType === 'card'">
              <button
                class="btn md__btn-primary mt-4 w-100"
                @click="getIdPayLinkInvoices"
              >
                Pagar ahora
              </button>
            </div>
            <div class="pay-tranfe" v-if="paymentType === 'transf'">
              <label class="font-semi-bold mt-4 d-block"
                >Referencia / Transacción ID</label
              >
              <el-input
                v-model="referenceBankId"
                placeholder="Ingresa la referencia de la transferencia o deposito"
              ></el-input>

              <button
                class="btn md__btn-primary mt-4 w-100"
                @click="sendPaymentReference"
              >
                Enviar referencia
              </button>

              <button
                class="btn md__btn-link"
                @click="dialogVisibleBankAccounts = true"
              >
                Consultar datos bancarios
              </button>

              <el-dialog
                :visible.sync="dialogVisibleBankAccounts"
                title="Datos bancarios"
                width="30%"
              >
                <div class="bg-color-gray p-3">
                  <div
                    class=" my-4"
                    v-for="(account, index) in getBankAccounts"
                    :key="index"
                  >
                    <h4 class="h4">
                      <span class="font-bold">TITULAR: </span
                      >{{ account.customer_name }}
                    </h4>
                    <p class="m-0">
                      BANCO: <strong>{{ account.name }}</strong>
                    </p>
                    <p class="m-0">
                      NÚMERO DE CUENTA:
                      <strong
                        >{{ account.type }} -
                        {{ account.account_number }}</strong
                      >
                    </p>
                  </div>
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <LogoComponent />
        </div>
      </b-col>
    </b-row>

    <el-dialog
      :visible.sync="dialogPaymentForm"
      :show-close="false"
      class="wrapper-dialog-form-datafast"
    >
      <DataFastPayPublic
        :action="actionFormDataFast"
        v-if="dialogPaymentForm"
      />
    </el-dialog>
  </b-container>
</template>

<script>
import InputAmount from "@/components/General/InputAmount";
import FormatAmount from "@/components/General/FormatAmount";
import ShareLinkWhatsapp from "@/components/General/ShareLinkWhatsapp";
import DataFastPayPublic from "@/components/General/GatewayPayments/DataFastPayPublic";
import LogoCard from "@/components/Logo/LogoCard";
import LogoComponent from "@/components/Logo/Logo";

import paymentService from "../services/paymentService";
import invoiceService from "../../invoices/services/invoiceService";
import { downloadFile } from "@/assets/utils/files";

export default {
  data() {
    return {
      dialogVisibleBankAccounts: false,
      dialogPaymentForm: false,
      paymentAmount: "total",
      paymentType: null,
      form: {
        total: 0,
        token: null,
        description: null
      },
      payment: null,
      referenceBankId: null
    };
  },
  computed: {
    isEnablePayCard() {
      return this.payment?.config?.pay_forms?.is_accept_card;
    },
    isEnablePayTransf() {
      return this.payment?.config?.pay_forms?.is_accept_transfer;
    },
    getBankAccounts() {
      return this.payment.bank_accounts ?? [];
    },
    getPaymentDescription() {
      return this.payment.description;
    },
    getTotal() {
      return this.paymentAmount === "total"
        ? this.payment.total
        : this.form.total;
    },
    actionFormDataFast() {
      const { href } = this.$router.resolve({
        name: "checkout.pay.links"
      });
      return href;
    }
  },
  methods: {
    updatePropsForm(props) {
      this.form = {
        ...this.form,
        ...props
      };
    },
    onInputAmount({ amount }) {
      this.updatePropsForm({ total: amount });
    },
    async getInfoInvoicePay() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        let response = await paymentService
          .getInfoInvoicePay({
            token: this.form.token
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });

        const { success, data } = response.data;
        if (success) {
          this.payment = data;
        }
      } catch (error) {
        return false;
      }
    },

    async invoiceDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { invoice_number, invoice_id: id } = this.payment;
        let response = await invoiceService
          .invoiceDownload({
            id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `Factura - #${invoice_number}`);
        }
      } catch (error) {
        return false;
      }
    },

    async getIdPayLinkInvoices() {
      if (!this.getTotal) {
        this.$notifications.warning({
          message: "Por favor ingrese un monto a pagar"
        });
        return false;
      }
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { token, description: descriptionPay } = this.payment;
        const { description: descriptionUser } = this.form;

        let response = await paymentService
          .getPpIdPayLinkInvoices({
            token,
            description: descriptionUser ?? descriptionPay,
            total: this.getTotal
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data: checkOutPayphone, success } = response.data;
        if (success && checkOutPayphone) {
          location.href = checkOutPayphone;
        }
      } catch (error) {
        return false;
      }
    },

    async sendPaymentReference() {
      if (!this.referenceBankId) {
        this.$notifications.warning({
          message: "Por favor ingrese la Referencia / Transacción ID"
        });
        return false;
      }
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { token } = this.payment;

        let response = await paymentService
          .sendPaymentReference({
            token,
            reference_id: this.referenceBankId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });

          this.referenceBankId = null;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    InputAmount,
    FormatAmount,
    ShareLinkWhatsapp,
    DataFastPayPublic,
    LogoCard,
    LogoComponent
  },
  mounted() {
    const { token } = this.$route.params;
    if (token) {
      this.updatePropsForm({ token });
      this.getInfoInvoicePay();
    }
  }
};
</script>

<style lang="scss" scoped></style>
