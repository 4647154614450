<template>
  <img src="https://www.datafast.com.ec/images/verified.png" id="poweredByDF" />
</template>

<script>
export default {
  name: "DataFastImageFooter"
};
</script>

<style lang="scss" scoped>
img {
  width: 200px;
  margin: auto !important;
}
</style>
