<template>
  <div>
    <form
      :action="actionForm"
      class="paymentWidgets"
      data-brands="VISA MASTER AMEX DINERS DISCOVER"
    ></form>
    <div class="d-flex justify-content-between align-items-center p-4">
      <LogoComponent width="150" />
      <DataFastImageFooter />
    </div>
  </div>
</template>

<script>
import DataFastImageFooter from "./DatafastFooter";
import LogoComponent from "@/components/Logo/Logo";

export default {
  name: "DataFastPayPublic",
  computed: {
    actionForm() {
      return this.action || window.location.href;
    }
  },
  components: {
    DataFastImageFooter,
    LogoComponent
  },
  props: {
    action: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
